/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-await */
/* eslint-disable object-shorthand */
/* eslint-disable eqeqeq */
import Api from 'util/Api';
import API from 'constants/api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { notification } from 'antd';
import { getCoordsFromPolygonGeoJson } from 'constants/operations';
import { useSelector } from 'react-redux';
import { authProfile } from 'redux/auth/selectors';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

export interface Point {
  lat: string;
  lng: string;
}

export interface Zone {
  id?: number;
  name?: string;
  detail?: string;
  fullName?: string;
  cdcId?: string | null;
  geoJson?: string;
  tenant?: number | null | undefined;
  logisticOperatorId?: number;
  groupingZone?: boolean;
  parent?: string;
  parentZone?: string;
  zipCodes?: string[];
  childrenZones?: Zone[] | number[];
  path?: Point[];
  active?: boolean;
  updated?: boolean;
}

const getZones = async (logisticOperatorId: number | string) => {
  const { data } = await Api.apiAxios.get(
    API.zones.getAllWithGeoJsonByLogisticOperator(logisticOperatorId),
  );
  if (!data) throw new Error('Missing zone data in payload.');
  return data.map((z: Zone) => ({
    ...z,
    fullName: `${z.name}${z.parentZone ? ` - ${z.parentZone}` : ''}`,
    path: z.geoJson ? getCoordsFromPolygonGeoJson(z.geoJson) : [],
    updated: false,
  }));
};

/**
 * @description
 * Crea una nueva zona
 */
export const useCreateZone = () => {
  const profile = useSelector(authProfile);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (zone: Zone) =>
      await Api.apiAxios.post(`${API.zone}`, zone),
    onMutate: async (newZone) => {
      await queryClient.cancelQueries({
        queryKey: ['zones', profile.logisticOperatorID],
      });
      const previousZonesData = queryClient.getQueryData([
        'zones',
        profile.logisticOperatorID,
      ]);
      queryClient.setQueryData(
        ['zones', profile.logisticOperatorID],
        (oldZonesData: any) => [
          ...oldZonesData,
          {
            ...newZone,
            fullName: `${newZone.name} - ${newZone.detail}`,
            updated: true,
          },
        ],
      );
      return {
        previousZonesData,
      };
    },
    onError: (e, _zone, context) => {
      queryClient.setQueryData(
        ['zones', profile.logisticOperatorID],
        context?.previousZonesData,
      );
      if (e instanceof Error)
        notification.error({
          message: 'Ocurrió un error al crear la zona',
          description: e.message,
        });
    },
    onSettled: () => {
      notification.success({
        message: 'Creación de zona',
        description:
          'Se confirmó la creación de la zona correctamente. Los cambios pueden tardar unos segundos en visualizarse.',
      });
      queryClient.invalidateQueries({
        queryKey: ['zones', profile.logisticOperatorID],
      });
    },
  });
};

/**
 * @description
 * Actualiza una zona
 */
export const useUpdateZone = () => {
  const profile = useSelector(authProfile);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (zone: Zone) =>
      await Api.apiAxios.put(`${API.zone}`, zone),
    onMutate: async (newZone) => {
      await queryClient.cancelQueries({
        queryKey: ['zones', profile.logisticOperatorID],
      });
      const previousZonesData = queryClient.getQueryData([
        'zones',
        profile.logisticOperatorID,
      ]);
      queryClient.setQueryData(
        ['zones', profile.logisticOperatorID],
        (oldZonesData: any) => {
          return oldZonesData.map((z: Zone) => {
            if (z.id != newZone.id) return z;
            return {
              ...newZone,
              fullName: `${newZone.name} - ${newZone.detail}`,
              updated: true,
            };
          });
        },
      );
      return {
        previousZonesData,
      };
    },
    onError: (e, _zone, context) => {
      queryClient.setQueryData(
        ['zones', profile.logisticOperatorID],
        context?.previousZonesData,
      );
      if (e instanceof Error)
        notification.error({
          message: 'Ocurrió un error al modificar la zona',
          description: e.message,
        });
    },
    onSettled: () => {
      notification.success({
        message: 'Edición de zona',
        description:
          'Se confirmó la edición de la zona correctamente. Los cambios pueden tardar unos segundos en visualizarse.',
      });
      queryClient.invalidateQueries({
        queryKey: ['zones', profile.logisticOperatorID],
      });
    },
  });
};

/**
 * @description
 * Elimina una zona
 */
export const useDeleteZone = () => {
  const profile = useSelector(authProfile);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (zone: Zone) =>
      await Api.apiAxios.delete(`${API.zones.deleteById(zone.id)}`),
    onMutate: async (zoneToDelete) => {
      await queryClient.cancelQueries({
        queryKey: ['zones', profile.logisticOperatorID],
      });
      const previousZonesData = queryClient.getQueryData([
        'zones',
        profile.logisticOperatorID,
      ]);
      queryClient.setQueryData(
        ['zones', profile.logisticOperatorID],
        (oldZonesData: any) => {
          return oldZonesData.filter((z: Zone) => z.id != zoneToDelete.id);
        },
      );
      return {
        previousZonesData,
      };
    },
    onError: (e, _zone, context) => {
      queryClient.setQueryData(
        ['zones', profile.logisticOperatorID],
        context?.previousZonesData,
      );
      if (e instanceof Error)
        notification.error({
          message: 'Ocurrió un error al eliminar la zona',
          description: e.message,
        });
    },
    onSettled: () => {
      notification.success({
        message: 'Eliminación de zona',
        description:
          'Se confirmó la eliminación de la zona correctamente. Los cambios pueden tardar unos segundos en visualizarse.',
      });
      queryClient.invalidateQueries({
        queryKey: ['zones', profile.logisticOperatorID],
      });
    },
  });
};

/**
 * @description
 * Crea una nueva zona agrupadora
 */
export const useCreateGroupingZone = () => {
  const profile = useSelector(authProfile);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (gZone: Zone) =>
      await Api.apiAxios.post(`${API.zones.grouping}`, gZone),
    onMutate: async (newGZone) => {
      await queryClient.cancelQueries({
        queryKey: ['groupingZones', profile.logisticOperatorID],
      });
      const previousGZonesData = queryClient.getQueryData([
        'groupingZones',
        profile.logisticOperatorID,
      ]);
      queryClient.setQueryData(
        ['groupingZones', profile.logisticOperatorID],
        (oldGZonesData: any) => [
          ...oldGZonesData,
          {
            ...newGZone,
            fullName: `${newGZone.name} - ${newGZone.detail}`,
            updated: true,
          },
        ],
      );
      return {
        previousGZonesData,
      };
    },
    onError: (e, _zone, context) => {
      queryClient.setQueryData(
        ['groupingZones', profile.logisticOperatorID],
        context?.previousGZonesData,
      );
      if (e instanceof Error)
        notification.error({
          message: 'Ocurrió un error al crear la zona agrupadora',
          description: e.message,
        });
    },
    onSettled: () => {
      notification.success({
        message: 'Creación de zona agrupadora',
        description:
          'Se confirmó la creación de la zona agrupadora correctamente. Los cambios pueden tardar unos segundos en visualizarse.',
      });
      queryClient.invalidateQueries({
        queryKey: ['zones', profile.logisticOperatorID],
      });
    },
  });
};

/**
 * @description
 * Actualiza una zona
 */
export const useUpdateGroupingZone = () => {
  const profile = useSelector(authProfile);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (gZone: Zone) =>
      await Api.apiAxios.put(`${API.zones.grouping}`, gZone),
    onMutate: async (newGZone) => {
      await queryClient.cancelQueries({
        queryKey: ['groupingZones', profile.logisticOperatorID],
      });
      const previousGZonesData = queryClient.getQueryData([
        'groupingZones',
        profile.logisticOperatorID,
      ]);
      queryClient.setQueryData(
        ['groupingZones', profile.logisticOperatorID],
        (oldGZonesData: any) => {
          return oldGZonesData.map((gz: Zone) => {
            if (gz.id != newGZone.id) return gz;
            return {
              ...newGZone,
              fullName: `${newGZone.name} - ${newGZone.detail}`,
              updated: true,
            };
          });
        },
      );
      return {
        previousGZonesData,
      };
    },
    onError: (e, _zone, context) => {
      queryClient.setQueryData(
        ['groupingZones', profile.logisticOperatorID],
        context?.previousGZonesData,
      );
      if (e instanceof Error)
        notification.error({
          message: 'Ocurrió un error al modificar la zona agrupadora',
          description: e.message,
        });
    },
    onSettled: () => {
      notification.success({
        message: 'Edición de zona agrupadora',
        description:
          'Se confirmó la edición de la zona agruapadora correctamente. Los cambios pueden tardar unos segundos en visualizarse.',
      });
      queryClient.invalidateQueries({
        queryKey: ['zones', profile.logisticOperatorID],
      });
    },
  });
};

/**
 * @description
 * Elimina una zona
 */
export const useDeleteGroupingZone = () => {
  const profile = useSelector(authProfile);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (gZone: Zone) =>
      await Api.apiAxios.delete(`${API.zones.deleteById(gZone.id)}`),
    onMutate: async (gZoneToDelete) => {
      await queryClient.cancelQueries({
        queryKey: ['groupingZones', profile.logisticOperatorID],
      });
      const previousGZonesData = queryClient.getQueryData([
        'groupingZones',
        profile.logisticOperatorID,
      ]);
      queryClient.setQueryData(
        ['groupingZones', profile.logisticOperatorID],
        (oldGZonesData: any) => {
          return oldGZonesData.filter((gz: Zone) => gz.id != gZoneToDelete.id);
        },
      );
      return {
        previousGZonesData,
      };
    },
    onError: (e, _zone, context) => {
      queryClient.setQueryData(
        ['groupingZones', profile.logisticOperatorID],
        context?.previousGZonesData,
      );
      if (e instanceof Error)
        notification.error({
          message: 'Ocurrió un error al eliminar la zona agrupadora',
          description: e.message,
        });
    },
    onSettled: () => {
      notification.success({
        message: 'Eliminación de zona agrupadora',
        description:
          'Se confirmó la eliminación de la zona correctamente. Los cambios pueden tardar unos segundos en visualizarse.',
      });
      queryClient.invalidateQueries({
        queryKey: ['zones', profile.logisticOperatorID],
      });
    },
  });
};

export const useZones = () => {
  const profile = useSelector(authProfile);
  const history = useHistory();
  const [shouldRefetch, setShouldRefetch] = useState<boolean>(false);

  const zones = useQuery({
    queryKey: ['zones', profile.logisticOperatorID],
    queryFn: async () => {
      return getZones(profile.logisticOperatorID).finally(() => {
        if (!shouldRefetch) setShouldRefetch(true);
      });
    },
    staleTime: 600000,
    placeholderData: [],
  });

  const getGroupingZones = async (logisticOperatorId: number | string) => {
    const { data } = await Api.apiAxios.get(
      API.zones.getGroupingWithGeoJsonByLogisticOperator(logisticOperatorId),
    );
    if (!data) throw new Error('Missing zone data in payload.');
    return data.map((gz: Zone) => {
      const childrenZones = !zones.isSuccess
        ? []
        : zones.data.filter((z: Zone) => z.parent == `/zones/${gz.id}`);

      return {
        ...gz,
        fullName: gz.name,
        childrenZones,
        updated: false,
      };
    });
  };

  const groupingZones = useQuery({
    queryKey: ['groupingZones', profile.logisticOperatorID],
    queryFn: () => getGroupingZones(profile.logisticOperatorID),
    staleTime: 600000,
    placeholderData: [],
    enabled: false,
  });

  useEffect(() => {
    if (
      zones.isSuccess &&
      !zones.isPending &&
      !zones.isFetching &&
      !zones.isLoading &&
      !zones.isPlaceholderData &&
      !groupingZones.isFetching &&
      shouldRefetch
    ) {
      setShouldRefetch(false);
      groupingZones.refetch();
    }
  }, [zones, shouldRefetch, groupingZones]);

  const allZones = useMemo(
    () => [...(zones.data || []), ...(groupingZones.data || [])],
    [zones.data, groupingZones.data],
  );

  function getZoneById(id: number | string) {
    if (!zones.isSuccess || !groupingZones.isSuccess) return {};
    const foundZone = allZones.find((z: Zone) => z.id == id);

    if (!foundZone) {
      notification.error({
        message: 'Hubo un error.',
        description: `La zona con ID ${id} no esta registrada en la base de datos`,
      });
      setTimeout(() => {
        history.goBack();
      }, 1500);
      return {};
    }

    return foundZone;
  }

  const zipCodes = useMemo(
    () =>
      zones.isSuccess && !!zones.data.length
        ? zones.data
            .map((z: Zone) => z.zipCodes)
            .flat()
            .filter((s: string | undefined) => s !== '' && s != undefined)
        : [],
    [zones],
  );

  const allZonesOptions = useMemo(() => {
    const zonesOptions = zones.isSuccess
      ? zones.data.map((z: Zone) => ({ label: z.fullName, value: z.id }))
      : [];
    const groupingZonesOptions = groupingZones.isSuccess
      ? groupingZones.data.map((z: Zone) => ({
          label: z.fullName,
          value: z.id,
        }))
      : [];
    return [
      {
        label: 'Zonas',
        title: 'zones',
        options: zonesOptions,
      },
      {
        label: 'Agrupadoras',
        title: 'groupingZones',
        options: groupingZonesOptions,
      },
    ];
  }, [zones, groupingZones]);

  return {
    zones: zones,
    groupingZones: groupingZones,
    zipCodes,
    getZoneById,
    allZonesOptions,
  };
};
